import styled from "@emotion/styled";
import { from } from "@/styles/responsive";
import defaultTheme from "@/styles/themes/default";
import UtilizeSpacer from "../../styles/theme-styles";
import { Body2, H5 } from "../Typography";

export const Container = styled.div`
  ${from.tabletLandscape`
      width: 70%;
    `}
`;

export const Title = styled(H5)`
  ${UtilizeSpacer({
    specificOption: {
      size: "3xs",
      direction: "bottom",
    },
    type: "margin",
  })}
  /* text-transform: lowercase; */

  &:first-letter {
    text-transform: capitalize;
  }
`;

export const SubTitle = styled(Body2)`
  ${UtilizeSpacer({
    specificOption: {
      size: "md",
      direction: "bottom",
    },
    type: "margin",
  })}
  color: ${defaultTheme.renewTheme.colors.black}
`;

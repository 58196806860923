import Button from "../Button";
import { H5 } from "../Typography";
import * as Styled from "./styles";

export default function IconHeaderCTA({
  icon,
  illustration,
  header,
  children,
  href,
  onClick,
  buttonLabel,
  href2,
  onClick2,
  buttonLabel2,
  classNames,
}: {
  icon?: JSX.Element;
  illustration?: JSX.Element;
  header: string;
  children?: JSX.Element;
  href?: string;
  buttonLabel?: string;
  href2?: string;
  onClick2?: () => void;
  buttonLabel2?: string;
  onClick?: () => void;
  classNames?: string;
}) {
  return (
    <Styled.Wrapper className={classNames}>
      {illustration ? (
        <Styled.IllustrationWrapper>{illustration}</Styled.IllustrationWrapper>
      ) : (
        <Styled.IconWrapper>{icon}</Styled.IconWrapper>
      )}
      <Styled.CopyWrapper>
        <H5>{header}</H5>
        {children}
        <Styled.ButtonWrapper>
          {(href || onClick) && buttonLabel && (
            <Button onClick={onClick} href={href}>
              {buttonLabel}
            </Button>
          )}
          {(href2 || onClick2) && buttonLabel2 && (
            <Button onClick={onClick2} href={href2} variant="secondary">
              {buttonLabel2}
            </Button>
          )}
        </Styled.ButtonWrapper>
      </Styled.CopyWrapper>
    </Styled.Wrapper>
  );
}

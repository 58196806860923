import { memo, MouseEvent } from "react";

import * as Styled from "./styles";
import type { Props } from "./types";

function Button({
  className,
  disabled,
  children,
  onClick,
  target,
  href,
  type,
  size = "large",
  variant = "primary",
  muiVariant,
  component,
  isLoading = false,
}: Props): JSX.Element {
  const onClickAction = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    if (onClick && !isLoading) onClick(e);
  };
  if (href) {
    return (
      <Styled.Link
        href={href}
        passHref
        target={target}
        rel={target === "_blank" ? "noreferrer noopener" : undefined}
      >
        <Styled.ContainerLink
          className={className}
          disabled={disabled}
          type={type}
          $variant={variant}
          $size={size}
        >
          {children}
        </Styled.ContainerLink>
      </Styled.Link>
    );
  }
  return (
    <Styled.Container
      variant={muiVariant}
      component={component}
      type={type}
      onClick={(e) => onClickAction(e)}
      className={className}
      disabled={disabled}
      $variant={variant}
      $size={size}
    >
      <span>{children}</span>
    </Styled.Container>
  );
}

export default memo(Button);

import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";

import { useRouter } from "next/router";
import defaultTheme from "@/styles/themes/default";
import * as Styled from "./styles";
import LogoLink from "../LogoLink";

const drawerWidth = 200;

export default function NavDrawer({
  links,
}: {
  links: {
    name: string;
    isHidden?: boolean;
    href?: string;
    onClick?: () => void;
    icon: JSX.Element;
  }[];
}) {
  const { asPath: pathname, push } = useRouter();
  return (
    <Drawer
      sx={{
        boxShadow:
          "0px 20px 24px -4px rgba(16, 24, 40, 0.10), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
        width: drawerWidth,
        flexShrink: 0,
        borderRight: 0,

        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          borderTopRightRadius: 16,
          borderBottomRightRadius: 16,
          borderRight: 0,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <LogoLink />

      <Styled.NavList>
        {links.map(
          (link) =>
            !link.isHidden && (
              <ListItem key={link.name} disablePadding>
                <Styled.ListLink
                  onClick={
                    link.onClick
                      ? link.onClick
                      : () => link.href && push(link.href)
                  }
                  color={
                    link.href && pathname.startsWith(link.href)
                      ? defaultTheme.renewTheme.colors.cobalt400
                      : "inherit"
                  }
                >
                  <Styled.ListIcon>{link.icon}</Styled.ListIcon>
                  <Styled.LinkText primary={link.name} />
                </Styled.ListLink>
              </ListItem>
            )
        )}
      </Styled.NavList>
    </Drawer>
  );
}

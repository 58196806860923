import UtilizeSpacer from "@/styles/theme-styles";
import styled from "@emotion/styled";
import defaultTheme from "@/styles/themes/default";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CopyWrapper = styled.div`
  ${UtilizeSpacer({
    specificOption: {
      size: "sm",
      direction: "bottom",
    },
    type: "margin",
  })}
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const IllustrationWrapper = styled.div`
  ${UtilizeSpacer({
    specificOption: {
      size: "md",
      direction: "vertical",
    },
    type: "margin",
  })}
  img {
    max-width: 342px;
    height: auto;
  }
`;

export const IconWrapper = styled(IllustrationWrapper)`
  align-items: center;
  background-color: ${defaultTheme.renewTheme.colors.arctic400};
  border-radius: 50%;
  display: flex;
  height: 4rem;
  justify-content: center;
  width: 4rem;
  * {
    fill: ${defaultTheme.renewTheme.colors.night400};
  }
`;

export const ButtonWrapper = styled.div`
  ${UtilizeSpacer({
    specificOption: {
      size: "sm",
      direction: "vertical",
    },
    type: "margin",
  })}
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  * {
    width: 100%;
  }
`;

import Link from "next/link";
import styled from "@emotion/styled";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import UtilizeSpacer from "@/styles/theme-styles";
import defaultTheme from "@/styles/themes/default";
import { textCommonStyles } from "../Typography";

export const LogoLink = styled(Link)`
  ${UtilizeSpacer({
    options: [
      {
        size: "2xs",
        direction: [0],
      },
    ],
    type: "padding",
  })}
  text-align: center;
`;

export const ListLink = styled(ListItemButton)`
  ${UtilizeSpacer({
    options: [
      {
        size: "2xs",
        direction: [0],
      },
      {
        size: "xs",
        direction: [1],
      },
    ],
    type: "padding",
  })}
  * {
    color: ${(props) => props.color};
    fill: ${(props) => props.color};
  }
  &:active,
  &:hover {
    * {
      color: ${defaultTheme.renewTheme.colors.cobalt400};
      fill: ${defaultTheme.renewTheme.colors.cobalt400};
    }
  }
  &:hover {
    background-color: ${defaultTheme.renewTheme.colors.arctic400};
  }
`;

export const ListIcon = styled(ListItemIcon)`
  min-width: 0;
  ${UtilizeSpacer({
    specificOption: {
      size: "2xs",
      direction: "right",
    },
    type: "margin",
  })}
`;

export const NavList = styled(List)`
  padding: 0;
`;

export const LinkText = styled(ListItemText)`
  ${textCommonStyles("md")}
  span {
    font-family: "Mint Grotesk";
  }
`;

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import RouterLink from "next/link";
import defaultTheme from "@/styles/themes/default";

import addAlpha from "@/utils/addAlpha";
import { Button } from "@mui/material";
import type { $ContainerProps } from "./types";

export const Container = styled(Button)<$ContainerProps>`
  align-items: center;
  text-transform: none;
  cursor: pointer;
  display: flex;
  font-weight: ${defaultTheme.renewTheme.weights.medium};
  justify-content: center;
  transition: all 200ms ease;

  :disabled {
    cursor: not-allowed;
  }

  ${({ $size }) => {
    switch ($size) {
      case "small":
        return css`
          border-radius: 0.5rem;
          font-size: 0.875rem;
          line-height: 1.25rem;
          padding: 0.5rem 1rem;
        `;
      case "large":
      default:
        return css`
          border-radius: 0.75rem;
          font-size: 0.9375rem;
          line-height: 1.3rem;
          padding: 0.5rem 1rem;
        `;
    }
  }}

  ${({ $variant }) => {
    switch ($variant) {
      case "secondary":
        return css`
          background-color: transparent;
          border: 0.125rem solid ${defaultTheme.renewTheme.colors.night400};
          color: ${defaultTheme.renewTheme.colors.night400};

          &:hover:not(:disabled) {
            background-color: transparent;
            border-color: ${defaultTheme.renewTheme.colors.night900};
            color: ${defaultTheme.renewTheme.colors.night900};
          }

          &:focus:not(:disabled) {
            background-color: transparent;
            border: ${defaultTheme.renewTheme.colors.cobalt400} 4px solid;
            color: ${defaultTheme.renewTheme.colors.cobalt900};
          }

          &:disabled {
            border-color: ${addAlpha(
              defaultTheme.renewTheme.colors.night400,
              0.5
            )};
            color: ${addAlpha(defaultTheme.renewTheme.colors.night400, 0.5)};
          }
        `;

      case "primary":
      default:
        return css`
          background-color: ${defaultTheme.renewTheme.colors.night400};
          border: 0.125rem solid ${defaultTheme.renewTheme.colors.night400};
          color: ${defaultTheme.renewTheme.colors.white};

          &:hover:not(:disabled) {
            background-color: ${defaultTheme.renewTheme.colors.night900};
            border-color: ${defaultTheme.renewTheme.colors.night900};
          }

          &:focus:not(:disabled) {
            border: ${defaultTheme.renewTheme.colors.cobalt400} 4px solid;
            background-color: ${defaultTheme.renewTheme.colors.cobalt900};
          }

          &:disabled {
            color: ${addAlpha(defaultTheme.renewTheme.colors.white, 0.5)};
            background-color: ${addAlpha(
              defaultTheme.renewTheme.colors.night400,
              0.5
            )};
            border: 0.125rem solid
              ${addAlpha(defaultTheme.renewTheme.colors.night400, 0.5)};
          }
        `;
    }
  }}
`;

export const ContainerLink = styled.button<$ContainerProps>`
  align-items: center;
  text-transform: none;
  cursor: pointer;
  display: flex;
  font-weight: ${defaultTheme.renewTheme.weights.medium};
  justify-content: center;
  transition: all 200ms ease;

  :disabled {
    cursor: not-allowed;
  }

  ${({ $size }) => {
    switch ($size) {
      case "small":
        return css`
          border-radius: 0.5rem;
          font-size: 0.875rem;
          line-height: 1.25rem;
          padding: 0.5rem 1rem;
        `;
      case "large":
      default:
        return css`
          border-radius: 0.75rem;
          font-size: 0.9375rem;
          line-height: 1.3rem;
          padding: 0.5rem 1rem;
        `;
    }
  }}

  ${({ $variant }) => {
    switch ($variant) {
      case "secondary":
        return css`
          background-color: transparent;
          border: 0.125rem solid ${defaultTheme.renewTheme.colors.night400};
          color: ${defaultTheme.renewTheme.colors.night400};

          &:hover:not(:disabled) {
            background-color: transparent;
            border-color: ${defaultTheme.renewTheme.colors.night900};
            color: ${defaultTheme.renewTheme.colors.night900};
          }

          &:focus:not(:disabled) {
            background-color: transparent;
            border: ${defaultTheme.renewTheme.colors.cobalt400} 4px solid;
            color: ${defaultTheme.renewTheme.colors.cobalt900};
          }

          &:disabled {
            border-color: ${addAlpha(
              defaultTheme.renewTheme.colors.night400,
              0.5
            )};
            color: ${addAlpha(defaultTheme.renewTheme.colors.night400, 0.5)};
          }
        `;

      case "primary":
      default:
        return css`
          background-color: ${defaultTheme.renewTheme.colors.night400};
          border: 0.125rem solid ${defaultTheme.renewTheme.colors.night400};
          color: ${defaultTheme.renewTheme.colors.white};

          &:hover:not(:disabled) {
            background-color: ${defaultTheme.renewTheme.colors.night900};
            border-color: ${defaultTheme.renewTheme.colors.night900};
          }

          &:focus:not(:disabled) {
            border: ${defaultTheme.renewTheme.colors.cobalt400} 4px solid;
            background-color: ${defaultTheme.renewTheme.colors.cobalt900};
          }

          &:disabled {
            color: ${addAlpha(defaultTheme.renewTheme.colors.white, 0.5)};
            background-color: ${addAlpha(
              defaultTheme.renewTheme.colors.night400,
              0.5
            )};
            border: 0.125rem solid
              ${addAlpha(defaultTheme.renewTheme.colors.night400, 0.5)};
          }
        `;
    }
  }}
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
`;

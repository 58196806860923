import { ReactNode } from "react";
import * as Styled from "./styles";

export default function PageHeader({
  title,
  copy,
  className,
}: {
  title: string;
  copy?: string | ReactNode;
  className?: string;
}) {
  return (
    <Styled.Container className={className}>
      <Styled.Title>{title}</Styled.Title>
      {copy && <Styled.SubTitle>{copy}</Styled.SubTitle>}
    </Styled.Container>
  );
}
